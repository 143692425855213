html, body {
  font-family: sans-serif;
  background-color: #faf7ea;
  color: #502503;
  font-size: 10px;
}

p {
  margin: 0;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 5px;
}

a {
  color: #502503;
}

input[type="text"],
input[type="email"],
input[type="tel"] {
  font-family: sans-serif;
  border-radius: 20px;
  border: none;
  color: #502503;
  font-size: 15px;
  padding: 10px;
  width: calc(100% - 2*10px);
}

label {
  font-size: 12px;
  line-height: 1;
  width: 100%;
  text-align: left;
}

button {
  background-color: #0d406a;
  color: #faf7ea;
  font-weight: 600;
  border-radius: 20px;
  border: none;
  padding: 10px 40px;
  font-size: 20px;
}

small {
  font-size: 11px;
  line-height: 1;
}

.app {
  min-width: 360px;
  max-width: 480px;
}

.register-page {
  text-align: center;
}

.hero-banner {
  width: 100%;
}

.content {
  padding: 0 20px 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  width: 100%;
  max-width: 300px;
  margin-bottom: 10px;
}
.row:first-child {
 margin-top: 10px;
}

.left {
  text-align: left;
  margin-left: 5px;
}

.grid-2-col {
  display: grid;
  grid-template-columns: auto auto;
  align-items: flex-start;
}

.agree-tnc-checkbox {
  margin: 0;
  margin-right: 8px;
}

.legal-docs {
  margin-bottom: 20px;
}

.language-selector {
  margin-bottom: 20px;
}
.language-selected {
  font-weight: 600;
}
.language-selector .language:after {
  content: " | ";
}
.language-selector .language:last-child:after {
  content: "";
}

.thank-you-page-background {
  background-image: image-set(
    url(./images/icc_thanks_1px_gradient@1x.png) 1x,
    url(./images/icc_thanks_1px_gradient@2x.png) 2x,
    url(./images/icc_thanks_1px_gradient@3x.png) 3x);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #d6d2b7;
}

.thank-you-page {
  position: relative;
  height: 100vh;
  background-image: image-set(
    url(./images/icc_thanks_banner@1x.png) 1x,
    url(./images/icc_thanks_banner@2x.png) 2x,
    url(./images/icc_thanks_banner@3x.png) 3x);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
}

.thank-you-page__share-link {
  text-decoration: none;
  /* background: rgba(255,0,0,0.1); */
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
